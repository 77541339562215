import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;

    .content-container {
        padding: 2em 0;

        .bg {
            width: 90%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 5%;
            z-index: -1;
            background-color: #F1F1F1;
            border-radius: 2em;
        }

        .headline {
            font-size: 1.7em;
            font-weight: 700;
            color: #262626;
            text-align: center;
            position: relative;
            top: .3em;
            padding: 0 3em;
            margin-bottom: 3.6em;
        }

        .benefits-desktop {
            width: 100%;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: wrap;

            > * {
                width: 23%;
            }
        }

        .benefits-mobile {
            display: none;
        }
    }

    @media all and (max-width: ${variables.tabletL}) {
        .content {
            .bg {
                width: 100%;
                left: 0;
            }

            .headline {
                margin-bottom: 3em;
            }

            .benefits-boxes {
                margin-top: 5em;
                justify-content: center;

                > * {
                    width: 45%;
                    margin: 3em 2%;
                }
            }
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        .public-layout-benefit-box {
            justify-content: center;
        }

        .layout-container {
            width: 100%;
            
            .content-container {
                .bg {
                    width: 200vw;
                    left: -50vw;
                    border-radius: 0;
                }

                .headline {
                    margin-bottom: 0;
                }

                .benefits-boxes {
                    margin-top: 0;
                    width: 100%;
                    display: block;

                    & > * {
                        width: auto;
                    }

                    .benefits-desktop {
                        display: none;
                    }

                    .benefits-mobile {
                        display: block;
                        margin: 2em 0;

                        .layout-mobile-slider {
                            padding-top: 5em;
                        }
                    }
                }
            }
        }
    }
`;
