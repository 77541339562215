import Component from './component';

Component.defaultProps = {
    subtitle: null,
    customClass: null,
    gender: null,
    usedGender: null,
    slug: null,
};

export default Component;