import { FunctionComponent } from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import { Routes } from 'types/routes';

import { Genders } from 'models/Product';

import { withVariables } from 'utils/string';

import StyledComponent from './styles';
import { GenderZoneProps, Props } from './types';

const PublicLayoutBenefitBox: FunctionComponent<Props> = ({
    title,
    subtitle,
    image,
    customClass,
    gender,
    slug,
    usedGender,
}) => {
    const correctGenderZone = (gen: Genders) => {
        const genderZone: GenderZoneProps = {
            male: Routes.PublicMenZone.substring(1),
            female: Routes.PublicWemanZone.substring(1),
        };

        if (gen) return genderZone[gen];
        return genderZone[usedGender] || genderZone.female;
    };

    if (!slug) {
        return (
            <StyledComponent className={(classnames('public-layout-benefit-box', customClass))}>

                <div className="benefit-box-container">
                    <img
                        className="icon"
                        src={image}
                        loading="lazy"
                        alt={title}
                    />
                    <div className="content">
                        <div className="box-copy">
                            <div
                                className="title"
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                            {subtitle && (
                                <div
                                    className="subtitle"
                                    dangerouslySetInnerHTML={{ __html: subtitle }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </StyledComponent>

        );
    }

    return (
        <StyledComponent className={(classnames('public-layout-benefit-box', customClass))}>

            <div className="clickable-container">
                <Link
                    href={withVariables(Routes.PublicProduct, {
                        genderZoneSlug: correctGenderZone(gender),
                        slug,
                    })}
                >
                    <div className="benefit-box-container">
                        <img
                            className="icon"
                            src={image}
                            loading="lazy"
                            alt={title}
                        />
                        <div className="content">
                            <div className="box-copy">
                                <div
                                    className="title"
                                    dangerouslySetInnerHTML={{ __html: title }}
                                />
                                {subtitle && (
                                    <div
                                        className="subtitle"
                                        dangerouslySetInnerHTML={{ __html: subtitle }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutBenefitBox;
