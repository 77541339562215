import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    border-radius: 1em;
    background-color: white;
    box-shadow: 0 .6em 1em #484a521a;

    .clickable-container {
        cursor: pointer;
    }

    .benefit-box-container {
        .content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            padding: 5em 2em 2em;

            .box-copy {
                width: 100%;

                .title {
                    font-weight: 700;
                    color: #3B3C43;
                    font-size: .9em;
                    max-width: 11em;
                    margin: auto;
                }

                .subtitle {
                    color: #3B3C43;
                    padding-top: 1em;
                    font-size: .9em;
                }
            }
        }

        .icon {
            position: absolute;
            top: -3em;
            width: auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    @media all and (max-width: ${variables.tabletL}) {
        .icon {
            height: 6em;
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        .content {
            .box-copy {
                font-size: 130%;

                .title {
                    font-size: 16px;

                }

                .subtitle {
                    font-size: 14px;
                }
            }
        }
    }
`;
