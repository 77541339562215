import { FunctionComponent } from 'react';

import SectionBenefits from 'components/modules/public/layout/sections/Benefits';

import StyledComponent from './styles';
import { Props } from './types';

const PublicSectionBenefitsVariantDefault: FunctionComponent<Props> = ({ translations }) => {
    return (
        <StyledComponent className="public-section-benefits-variant-default">
            <SectionBenefits {...translations} />
        </StyledComponent>
    );
};

export default PublicSectionBenefitsVariantDefault;
