import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    max-width: 1140px;
    margin: auto;
    padding: ${variables.sectionPadding};
    overflow-x: hidden;

    .list {
        position: relative;
        padding: 6em 0 3em;

        .layout-slick {
            .slick-slider {
                .slick-list {
                    .slick-track {
                        gap: unset;

                        .public-layout-benefit-box {
                            height: 100%;
                        }

                        .slick-slide {
                            display: flex;
                            height: inherit;
                            margin: 0 1em;
                        }
                    }
                }
            }
        }

        .boxes-group-container {
            display: flex;
            justify-content: center;

            .public-layout-benefit-box {
                width: 358px;
                margin: 4em 1em 1em;
            }
        }
    }

    @media all and (max-width: ${variables.tabletL}) {
        .tabs-container {
            .nav {
                &.styled-h-scrollbar {
                    justify-content: center;
                }
            }
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        padding: ${variables.sectionPaddingMobile};

        .layout-container {
            width: 100%;

            .headline {
                padding: 0 5%;
            }
        }

        .children {
            margin-bottom: 0;
        }

        .list {
            display: none;
        }

        .layout-mobile-slider {
            padding-top: 6em;
        }

        .layout-tab-filters {
            .tabs-container {
                .content {
                    padding-bottom: 0;
                }
            }
        }
    }
`;
