import { FunctionComponent, useContext } from 'react';
import dynamic from 'next/dynamic';

import DeviceContext from 'components/context/Device';
import LayoutContainer, { Sizes } from 'components/layout/LayoutContainer';
import BenefitBox from 'components/modules/public/layout/boxes/BenefitBox';

import StyledComponent from './styles';
import { Props } from './types';

const MobileSlider = dynamic(() => import('components/layout/MobileSlider'));

const PublicLayoutSectionBenefits: FunctionComponent<Props> = ({ headline, boxes }) => {
    const deviceContext = useContext(DeviceContext);

    return (
        <StyledComponent className="public-layout-section-benefits">
            <LayoutContainer size={Sizes.XMedium}>
                <div className="content-container">
                    <div className="bg" />
                    <div
                        className="headline"
                        dangerouslySetInnerHTML={{ __html: headline }}
                    />
                    {Array.isArray(boxes) && (
                        <div className="benefits-boxes">
                            {!deviceContext?.isMobile && (
                                <div className="benefits-desktop">
                                    {boxes.map((box) => (
                                        <BenefitBox
                                            key={box.key || box.title}
                                            {...box}
                                        />
                                    ))}
                                </div>
                            )}
                            {deviceContext?.isMobile && (
                                <div className="benefits-mobile">
                                    <MobileSlider>
                                        {boxes.map((box) => (
                                            <BenefitBox
                                                key={box.key || box.title}
                                                {...box}
                                            />
                                        ))}
                                    </MobileSlider>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicLayoutSectionBenefits;
